.matrix-line {
  position: absolute;
  width: 1px;
  white-space: nowrap;
  margin-left: 7px;
  color: #138a36;
  opacity: 0;
  animation: drop 0.5s linear infinite;
  .matrix-binary {
    transform: rotateZ(90deg);
  }
}

@keyframes drop {
  0% {
    transform: translateY(0vh);
    opacity: 0.7;
  }
  100% {
    transform: translateY(120vh);
  }
}
